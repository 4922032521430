import * as React from "react";
import AddToCartButtonCard from "../cart/addToCartButton/addToCartButton";
import Chip from "@mui/material/Chip";
import ReportIcon from "@mui/icons-material/Report";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Stack from "@mui/material/Stack";
import * as StyledComponets from "./productCard.module.scss";
import snow from "../../icons/snow.svg";

// markup
const ProductCard = ({
  image,
  sku,
  brand,
  name,
  stylenum,
  price,
  diam,
  width,
  season,
  color,
  finish,
  offset,
  pcd1,
  pcd2,
  supplier,
  lifted,
}) => {
  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });
  if (stylenum === null) {
    stylenum = "";
  }
  if (color === null) {
    color = "";
  }
  var xoff = "";
  if (offset > 0) {
    xoff = "+";
  }
  function isWinterProof() {
    if (season !== "winter") {
      return <div className={StyledComponets.snow}></div>;
    } else {
      return (
        <img className={StyledComponets.snow} src={snow} alt="winter proof" />
      );
    }
  }

  function productStar() {
    if (supplier !== "Wheel Pros") {
      return <div className={StyledComponets.star}></div>;
    } else {
      return (
        <div className={StyledComponets.star}>
          <p>&#9733;</p>
        </div>
      );
    }
  }

  function isLifted() {
    if (lifted === true) {
      return (
        <Stack direction="row" spacing={1}>
          <Chip
            icon={<ControlPointIcon />}
            label="DÉTAILS"
            variant="outlined"
            color="success"
          />
          <Chip
            icon={<ReportIcon />}
            label="MONTAGE AGRESSIF !"
            variant="outlined"
            color="error"
          />
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" spacing={1}>
          <Chip
            icon={<ControlPointIcon />}
            label="DÉTAILS"
            variant="outlined"
            color="success"
          />
        </Stack>
      );
    }
  }

  //returns pcd2 if exists
  function pcd2Disp() {
    if (pcd2 === null || pcd2 === "") {
      return "";
    } else {
      return " | " + pcd2;
    }
  }

  let URL;
  if (lifted === true) {
    URL = "/wheel?sku=" + sku + "&ag=true";
  } else {
    URL = "/wheel?sku=" + sku;
  }

  price = Number.parseFloat(price).toFixed(2);

  return (
    <article className={StyledComponets.card} key={sku}>
      <a href={URL} target="_blank" rel="noreferrer">
        <div className={StyledComponets.snowContainer}>{isLifted()}</div>
        <h3>{brand}</h3>
        <h4>{name + " " + stylenum}</h4>
        <h5>
          {diam +
            "x" +
            width +
            " | " +
            xoff +
            offset +
            " | " +
            pcd1 +
            pcd2Disp()}
        </h5>
        <p className={StyledComponets.sku}>{sku}</p>
        <div className={StyledComponets.magimage}>
          <img
            loading="lazy"
            src={image}
            alt={
              brand +
              " " +
              name +
              " " +
              diam +
              "x" +
              width +
              " " +
              color +
              " " +
              finish
            }
          />
        </div>

        <h6 className={StyledComponets.colorFinish}>{color + finish}</h6>
        <div className={StyledComponets.price}>
          <h2>{formatter.format(price)}</h2>
          <p>&nbsp;ch.</p>
        </div>
      </a>
      <AddToCartButtonCard
        ximage={image}
        xsku={sku}
        xbrand={brand}
        xname={name}
        xstylenum={stylenum}
        xprice={price}
        xdiam={diam}
        xwidth={width}
        xseason={season}
        xcolor={color}
        xfinish={finish}
        xoffset={offset}
        xpcd1={pcd1}
        xpcd2={pcd2}
        xsupplier={supplier}
      />
    </article>
  );
};
export default ProductCard;
